import { ConstructionOutlined } from "@material-ui/icons";
import axios from "axios";
import { Api, ApiKey } from "../ApiCredencial";
import { FILTER_INBOX } from "../constants";
import { AZ_TASK_MANAGER_API, AZ_TASK_MANAGER_HEADERS } from "../tasks/ApiCred";
import { AZ_ADMIN_API, AZ_ADMIN_HEADERS } from "../users/ApiCred";

export const inboxSearch = (searchTerm) => ({
  type: FILTER_INBOX,
  searchTerm,
});

let inb = {
  data: [
    {
      row_no: 1,
      id: 23,
      name: "Bootcamp",
      type: "Course",
      description: "New Joinee Bootcamp",
      task_level: "Kitchen",
      url: "",
      status: "Open",
      created_by: "Shanil",
      kitchen: "HSR",
      day: "Sunday",
      time: "10:00 AM",
      user: "None",
      comments: null,
    },
    {
      row_no: 2,
      id: 27,
      name: "Discipline Management",
      type: "Quiz",
      description: "Discipline Managment Questions",
      task_level: "Kitchen",
      url: "",
      status: "Open",
      created_by: "Krishna",
      kitchen: "HSR",
      day: "Sunday",
      time: "12:00 PM",
      user: "Mohammed Ali #852",
      comments: "done",
    },
    {
      row_no: 3,
      id: 29,
      name: "Process Management",
      type: "Quiz",
      description: "Process Managment Questions",
      task_level: "Kitchen",
      url: "",
      status: "Open",
      created_by: "Krishna",
      kitchen: "HSR",
      day: "Sunday",
      time: "3:00 PM",
      user: "Mohammed Ali #852",
      comments: "done",
    },
    {
      row_no: 4,
      id: 30,
      name: "Closing Checklist",
      type: "Task",
      description: "Closing Checklist",
      task_level: "Kitchen",
      url: "https://docs.google.com/forms/d/e/1FAIpQLScrhpVt_-fiJB4n5eHN_xGUarWHhkdtiHLeJIn_DKdmQy3KvQ/viewform",
      status: "Open",
      created_by: "Shanil",
      kitchen: "HSR",
      day: "Sunday",
      time: "10:00 PM",
      user: "None",
      comments: null,
    },
    {
      row_no: 5,
      id: 31,
      name: "Review OOS & Perform Stock In",
      type: "Task",
      description: "Review OOS & Perform Stock In in KAC",
      task_level: "Kitchen",
      url: "",
      status: "Open",
      created_by: "Shanil",
      kitchen: "HSR",
      day: "Sunday",
      time: "12:00 PM",
      user: "None",
      comments: null,
    },
    {
      row_no: 6,
      id: 32,
      name: "Review OOS & Perform Stock In",
      type: "Task",
      description: "Review OOS & Perform Stock In in KAC",
      task_level: "Kitchen",
      url: "",
      status: "Open",
      created_by: "Shanil",
      kitchen: "HSR",
      day: "Sunday",
      time: "3:00 PM",
      user: "None",
      comments: null,
    },
    {
      row_no: 7,
      id: 33,
      name: "Review OOS & Perform Stock In",
      type: "Task",
      description: "Review OOS & Perform Stock In in KAC",
      task_level: "Kitchen",
      url: "",
      status: "Open",
      created_by: "Shanil",
      kitchen: "HSR",
      day: "Sunday",
      time: "6:00 PM",
      user: "None",
      comments: null,
    },
    {
      row_no: 8,
      id: 34,
      name: "Review OOS & Perform Stock In",
      type: "Task",
      description: "Review OOS & Perform Stock In in KAC",
      task_level: "Kitchen",
      url: "",
      status: "Open",
      created_by: "Shanil",
      kitchen: "HSR",
      day: "Sunday",
      time: "9:00 PM",
      user: "None",
      comments: null,
    },
    {
      row_no: 9,
      id: 35,
      name: "Review OOS & Perform Stock In",
      type: "Task",
      description: "Review OOS & Perform Stock In in KAC",
      task_level: "Kitchen",
      url: "",
      status: "Open",
      created_by: "Shanil",
      kitchen: "HSR",
      day: "Sunday",
      time: "11:00 PM",
      user: "None",
      comments: null,
    },
    {
      row_no: 10,
      id: 38,
      name: "Check Restaurant Status in Aggregator",
      type: "Task",
      description:
        "Check Restaurant Status in Aggregator and report the anomaly",
      task_level: "Kitchen",
      url: "",
      status: "Open",
      created_by: "Shanil",
      kitchen: "HSR",
      day: "Sunday",
      time: "1:00 PM",
      user: "None",
      comments: null,
    },
  ],
  total_rows: 11,
};

export const fetchInbox = (location_id) => (dispatch) => {
  //console.log("Location Id", location_id);
  const userId = window.sessionStorage.getItem("userId");
  // console.log(userId, "userISd");
  const url = `${AZ_TASK_MANAGER_API}/inbox/user?id=${userId}&status=open&component=browser`;
  //const url = `http://localhost:7071/api/inbox/user?id=${userId}&status=open&component=browser`
  axios
    .get(url, { headers: { ...AZ_TASK_MANAGER_HEADERS } })
    .then((response) => {
      dispatch({
        type: "FETCH_INBOX",
        inbox: response.data,
      });
    })
    .catch((err) => err);
};

export const openInboxItem = (
  itemid,
  itemtype,
  itemkitchen,
  mainItem,
  programId
) => ({
  type: "OPEN_INBOX_ITEM",
  itemid,
  itemtype,
  itemkitchen,
  mainItem,
  programId,
});
export const fetchComplete = () => (dispatch) => {
  const userId = window.sessionStorage.getItem("userId");
  const location_id = window.sessionStorage.getItem("locAtr");
  const url = `${AZ_TASK_MANAGER_API}/inbox/user?id=${userId}&location=${location_id}&startDate=&endDate=&status=done`;
  axios
    .get(url, { headers: { ...AZ_TASK_MANAGER_HEADERS } })
    .then((response) => {
      //console.log("complete response", response);
      dispatch({
        type: "FETCH_COMPLETE",
        complete: response.data,
      });
    })
    .catch((err) => err);
};

export const fetchUserEarning = (from, to) => (dispatch) => {
  const userId = window.sessionStorage.getItem("userId");
  const tenantId = window.sessionStorage.getItem("tenantId");
  const url = `${AZ_ADMIN_API}/api/earnings-admin-user?tenant_id=${tenantId}&startDate=${from}&endDate=${to}&user_id=${userId}`;
  axios
    .get(url, { headers: { ...AZ_ADMIN_HEADERS } })
    .then((response) => {
      console.log("complete response", response.data);
      dispatch({
        type: "FETCH_USER_EARNING",
        earning: response.data.data,
      });
    })
    .catch((err) => err);
};

export const fetchAdhocTasksList = () => (dispatch) => {
  const user_id = window.sessionStorage.getItem("userId");
  const url = `${AZ_TASK_MANAGER_API}/adhoc-task?user_id=${user_id}&status='open'`;
  axios
    .get(url, { headers: { ...AZ_TASK_MANAGER_HEADERS } })
    .then((response) => {
      console.log("response", response);
      dispatch({
        type: "FETCH_ADHOC_TASK_LIST",
        adhocTaskList: response.data,
        adhocLoaded: true,
        adhocModelLoaded: true,
      });
    })
    .catch((err) => err);
};

export const fetchAdhocTask = (id) => (dispatch) => {
  const url = `${AZ_TASK_MANAGER_API}/adhoc-task/${id}`;
  if (id === -1) {
    dispatch({
      type: "FETCH_ADHOC_TASK",
      adhocTask: {
        id: "-1",
        tenant_id: window.sessionStorage.getItem("tenantId"),
        date_time: "",
        user_id: window.sessionStorage.getItem("userId"),
        url: "[]",
      },
      adhocModelLoaded: false,
    });
  } else {
    axios
      .get(url, { headers: { ...AZ_TASK_MANAGER_HEADERS } })
      .then((response) => {
        console.log("response", response);
        dispatch({
          type: "FETCH_ADHOC_TASK",
          adhocTask: response.data,
          adhocModelLoaded: false,
        });
      })
      .catch((err) => err);
  }
};

export const fetchAuditReport = (user, approver, task, location) => (dispatch) => {
  const reportJson = {
    location: "Koramangala",
    total_received_production: 330,
    total_received_other: 171,
    total_point_production: 336,
    total_point_other: 171,
    date: "11/11/2024",
    time: "1:40 PM",
    detailedReport: [{
      department: "cake",
      desc: "Cake slicer welding need to be done",
      type: "Major",
      code: "",
      rootCause: "Management Negligence",
      correctiveAction: "",
      preventiveAction: "",
      closingDate: "25/11/2024",
      dateClosed: "",
      before_image: "",
      after_image: ""
    },
    {
      department: "kitchen",
      desc: "Freezer racks rusting,possibility of contamination",
      type: "Major",
      code: "EQ-2",
      rootCause: "Management Negligence",
      correctiveAction: "",
      preventiveAction: "",
      closingDate: "25/11/2024",
      dateClosed: "",
      before_image: "",
      after_image: ""
    }

    ],
    auditBy: "Ankit",
    productionDepartmentReport: [{
      name: "COUNTER",
      stdPoint: "57",
      actualPoint: "57",
    },
    {
      name: "PANTRY",
      stdPoint: "60",
      actualPoint: "60",
    },
    {
      name: "PUFF",
      stdPoint: "46",
      actualPoint: "46",
    },
    {
      name: "CAKE",
      stdPoint: "44",
      actualPoint: "41",
    },
    {
      name: "KITCHEN",
      stdPoint: "71",
      actualPoint: "68",
    },
    {
      name: "PIZZA",
      stdPoint: "58",
      actualPoint: "58",
    }

    ],
    otherDepartmentReport: [{
      name: "POT WASH",
      stdPoint: "24",
      actualPoint: "24",
    },
    {
      name: "CUSTOMER",
      stdPoint: "10",
      actualPoint: "10",
    },
    {
      name: "RESTROOM",
      stdPoint: "6",
      actualPoint: "6",
    },
    {
      name: "STAFF",
      stdPoint: "19",
      actualPoint: "19",
    },
    {
      name: "STORE",
      stdPoint: "25",
      actualPoint: "25",
    },
    {
      name: "COMMON AREA",
      stdPoint: "25",
      actualPoint: "25",
    },
    {
      name: "MAINTENANCE",
      stdPoint: "16",
      actualPoint: "16",
    },
    {
      name: "CHECKLISTS",
      stdPoint: "26",
      actualPoint: "26",
    },
    {
      name: "ADMINISTRATION",
      stdPoint: "20",
      actualPoint: "20",
    },
    ]

  }
  const url = `${AZ_TASK_MANAGER_API}/inbox-user-report?user_id=${user}&approver_id=${approver}&task=${task}&location=${location}`;
  //const url = `http://localhost:7071/api/inbox-user-report?user_id=${user}&approver_id=${approver}&task=${task}&location=${location}`
  axios
    .get(url, { headers: { ...AZ_TASK_MANAGER_HEADERS } })
    .then((response) => {
      console.log('response report', response)
      dispatch({
        type: "AUDIT_REPORT",
        reportJson: response.data,
        reportLoaded: false
      })
    })
    .catch((err) => err)

  // dispatch({
  //   type:"AUDIT_REPORT",
  //   reportJson:reportJson,
  //   reportLoaded :false
  // })
}

export const persistAuditReport = (data) => (dispatch) => {
  const report = JSON.parse(JSON.stringify(data))
  const user_name = window.sessionStorage.getItem("firstName")
  const tenant_id = window.sessionStorage.getItem("tenantId")
  report["submit_by"] = user_name
  report["tenant_id"] = tenant_id

  const url = `${AZ_TASK_MANAGER_API}/inbox-user-report`;
  //const url = `http://localhost:7071/api/inbox-user-report`
  axios
    .post(url, report, { headers: { ...AZ_TASK_MANAGER_HEADERS } })
    .then((response) => {
      console.log(" persiit response", response)
      dispatch({
        type: "AUDIT_REPORT_PERSIST",
        reportJson: {
          detailedReport: []
        },
        reportLoaded: false
      })
    })

}

export const updateReport = (data, attr, index, value) => (dispatch) => {
  JSON.parse(JSON.stringify(data))
  console.log(data, attr, index, value)
  data["detailedReport"][index][attr] = value
  console.log(data)
  dispatch({
    type: "AUDIT_REPORT_UPDATE",
    reportJson: data,
    reportLoaded: false
  })
}
export const updateLoadedReport = () => (dispatch) => {
  dispatch({
    type: "AUDIT_REPORT",
    reportJson: {},
    reportLoaded: true
  })
}

export const persistAdhocTask = (adhoc) => (dispatch) => {
  let audit = adhoc;
  audit["detailedReport"] = stringifyAudit(audit["detailedReport"])
  const url = `${AZ_TASK_MANAGER_API}/adhoc-task/${adhoc.id}`;
  //const url = "";
  console.log("Adhoc", adhoc);
  axios
    .post(url, audit, { headers: { ...AZ_TASK_MANAGER_HEADERS } })
    .then((response) => {
      console.log("response", response);
      dispatch({
        type: "PERSIST_ADHOC_TASK",
        adhocTask: { url: [] },
        adhocSuccess: response.data.response,
      });
    })
    .catch((error) => error);
};
export const updateAdhocTask = (adhoc, attr, value) => (dispatch) => {
  //console.log("adhoc", adhoc, attr, value);
  let a = JSON.parse(JSON.stringify(adhoc));
  a[attr] = value;
  //console.log("adhoc 2", a);
  dispatch({
    type: "UPDATE_ADHOC_TASK",
    adhocTask: a,
  });
};
export const updateAdhocModelLoaded = () => (dispatch) => {
  dispatch({
    type: "UPDATE_MODEL_LOADED",
    adhocModelLoaded: true,
  });
};

export const updateAdhocModelToNull = () => (dispatch) => {
  dispatch({
    type: "UPDATE_ADHOC_MODEL_NULL",
    adhocTask: { url: [] },
  });
};
export const earningConfig = () => (dispatch) => {
  //console.log("called action for config");
  const url = `${AZ_TASK_MANAGER_API}/config/earnings`;
  axios
    .get(url, { headers: { ...AZ_TASK_MANAGER_HEADERS } })
    .then((response) => {
      //console.log("response", response);
      dispatch({
        type: "EARNING_CONFIG",
        config: response.data,
      });
    })
    .catch((err) => err);
};

const stringifyAudit = (data) => {
  if (data.length > 0) {
    data.map((row, index) => {
      data[index].before_image = JSON.stringify(data[index].before_image)
    })
  }
  return data
}